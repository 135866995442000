// userSetup.scss

.user-setup-form {
    .setup-form {
        width: 20vw;
    }

    .setup-section {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .form-control {
        text-align: center;
    }

    .form-label {
        padding-bottom: 5%;
    }

    // Progress bar
    .progress-bar-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;

        div {
            display: flex;
            height: 5px;
            background-color: #ddd;

            div {
                flex: 1;
                transition: background-color 0.3s ease;
            }
        }
    }
}
